body {
  padding: 0;
  margin: 0;
  background-color: #282c34;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-page {
  background: #282c34;
  /* width: 100vw; */
  /* height: 100vh; */
  display: flex;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}

.main-page-wrapper {
  padding: 3rem 3rem;
  width: 90%;
  /* min-height: 90%; */
}

.nav-bar {
  font-size: 2rem;
  color: white;
  font-weight: 600;
  width: 100%;
}

.add-btn {  
  top: 90%;
  position: fixed;
  font-size: 1.5rem;
  color: #202123;
  background-color: #f7df1e;
  padding: 0.75rem 3rem;
  border-radius: 25px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.add-btn-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.posting-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-color: #202123;
  border-radius: 15px;
  color: #f7df1e;
  font-weight: 600;
  display: flex;
  width: 50%;
  flex-direction: column;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.input-wrapper {
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;
  justify-content: space-between;
}

.input-wrapper label {
  margin-right: 1rem;
}

.input-wrapper input, .input-wrapper textarea {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  background-color: #282c34;
  border: none;
  box-shadow: none;
  outline: none;
  color: #f7df1e;
  flex-grow: 0.8;
  font-size: 1rem;
}

.input-wrapper textarea {
  height: 150px;
}

.choose-file-btn {
  background-color: #282c34;
  padding: 0.4rem 1.5rem;
  border-radius: 15px;
  cursor: pointer;
}

.choose-file-btn:hover {
  opacity: 0.5;
}

.submit-btn {
  background-color: #282c34;
  padding: 0.4rem 1.5rem;
  border-radius: 15px;
  align-self: center;
  margin-top: 1rem;
  cursor: pointer;
}

.error-msg {
  color: red;
}

.submit-btn:hover {
  opacity: 0.5;
}

.close-btn {
  cursor: pointer;
}

.close-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.success-msg {
  color: green;
}

.panel-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.close-btn {
  color: #f7df1e;
  font-weight: 600;
}

.panel {
  background-color: #202123;
  padding: 1.5rem;
  color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
  width: 14rem;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.media-container {
  margin: 0.5rem 0;
  height: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.media-container img, .large-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.large-container {
  margin: 0.5rem 0;
  height: 20rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.panel-content {
  margin: 0;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  max-height: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.like-dislike-wrapper {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  flex-grow: 1;
  margin-top: 0.5rem;
}

.arrow {
  height: fit-content;
  margin-right: 0.5rem;
  cursor: pointer;
}

.sort-by-wrapper {
  display: flex;
  justify-content: flex-end;
}

.sort-by-btn {
  color: #282c34;
  background-color: #f7df1e;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 15px;
  cursor: pointer;
}

.sort-by-dropdown {
  position: absolute;
  color: #282c34;
  background-color: #f7df1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  padding-top: 0.5rem;
  border-radius: 15px;
}

.dropdown-opt {
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.panel-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  background-color: #202123;
  border-radius: 15px;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  width: 60%;
}

.nb-spinner {
  position: absolute;
  width: 10rem;
  height: 10rem;
  margin: 0;
  background: transparent;
  border-top: 4px solid #009688;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

.loading-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}